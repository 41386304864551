import React from 'react';
import Layout from '../components/Layout';
import styles from './bodytext.module.css'; // Import the CSS module

const Cabinets = () => {
    return (
        <Layout>
            <div className={styles.textContainer}>
            <h2>Rent Cabinet Space</h2>
            <p>We rent cabinets from £35 without any extra commission!</p>
            <p>Please note to ensure a varied range within the shop we do not operate on a time based waiting list, we will process cabinet requests based on the range and type of items.</p>
	    <br />
            <p>Please get in touch with photos and a brief description of the type of items that you would like to sell from your cabinet.</p>
            </div>
        </Layout>
    );
};

export default Cabinets;