import React from 'react';
import Header from './Header';
import Footer from './Footer';
import styles from './Layout.module.css'; // Import CSS module

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className={styles.container}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;