import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/vintageinoultonlogo.png';
import './Header.css';

const Header = () => {
    return (
        <header className="header">
            <div className="logo">
                <Link to="/">
                    <img src={logo} alt="Vintage in Oulton" className="logo-image" />
                </Link>
            </div>
            <nav>
                <ul className="navList">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/About">About</Link></li>
                    <li><Link to="/Contact">Contact</Link></li>
                    <li><Link to="/Cabinets">Cabinets</Link></li>
                    <li><Link to="/Annie">Annie Sloan</Link></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
