import React from 'react';
import Layout from '../components/Layout';
import styles from './bodytext.module.css'; // Import the CSS module

const About = () => {
  return (
    <Layout>
      <div className={styles.textContainer}>
      <h2>About Us</h2>
      <p>
        Vintage in Oulton is your destination for quality vintage and antique
        items, Annie Sloan paint, and more. Our curated collection features a
        wide range of unique pieces sourced from around the world.
      </p>
      <br />
      <h2>Opening Times</h2>
      <p>Opening Times are subject to change on public holidays.</p>
      <p>
        Please call ahead if you are travelling far, we strive to be open
        according to our stated opening hours but sometimes these can change.
      </p>
      <br />
      <br />
      <p>Monday: 10:00-17:00</p>
      <p>Tuesday: Closed</p>
      <p>Wednesday: 10:00-17:00</p>
      <p>Thursday: 10:00-17:00</p>
      <p>Friday: 10:00-17:00</p>
      <p>Saturday: 10:00-17:00</p>
      <p>Sunday: 11:00-16:00</p>
      <br />
      <p>Opening Times are subject to change on public holidays.</p>
      <p>Check our social media or give us a call for the latest public holiday hours.</p>
      </div>
    </Layout>
  );
};

export default About;
