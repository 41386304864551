import React from 'react';
import Layout from '../components/Layout';
import styles from './bodytext.module.css'; // Import the CSS module

const Home = () => {
    return (
        <Layout>
		<div className={styles.textContainer}>
        <h2>Welcome to Vintage in Oulton</h2>
        <br />
		<p>We have an eclectic range! With different dealers in house we have a fantastic mix of antiques, furniture, mid-century pieces, vinyl records, reclamation, pictures, mirrors, vintage clothes, retro, industrial and collectibles. We also have a small range of new and vintage style indoor and outdoor items.</p>
		<br />
		<p>Stockists of Annie Sloan Chalk Paint and The Yorkshire Candle Company.</p>
		<br />
		<p>Follow us on Facebook and Instagram for our newest stock!</p>
		<br />
		<p>We buy a large range of items from the general public. See our 'Contact' page if you have things to sell.</p>
		<br />
		<p>For full domestic and commercial clearances we work with a local 5* rated company to pay you for antique and vintage items that might usually be charged to remove. They can provide you with a free quote for domestic and commercial clearances, get in touch for their details.</p>
		</div>
		</Layout>
    );
};

export default Home;
