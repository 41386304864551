import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import styles from './bodytext.module.css'; // Import the CSS module

const Contact = () => {
  useEffect(() => {
    // Function to initialize Google Analytics
    const initializeGtag = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', 'G-B0Z8R6STW8');
    };

    // Load Google Tag Manager script
    const gtagScript = document.createElement('script');
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-B0Z8R6STW8';
    gtagScript.async = true;
    gtagScript.onload = initializeGtag;
    document.head.appendChild(gtagScript);

    // Define initMap function globally
    window.initMap = function() {
      if (window.google) {
        const map = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat: 53.75252211029793, lng: -1.4471098680939694 },
          zoom: 17,
          mapId: '964502a05443f89e'  // Replace with your actual Map ID
        });

        const contentString = `
          <div style="background: white; padding: 5px; border-radius: 5px;">
            <h3>Vintage in Oulton</h3>
            <p>81-83 Aberford Road, Leeds, LS26 8SL, United Kingdom</p>
            <a href="https://www.google.com/maps/dir/Current+Location/81-83 Aberford Road, Leeds, United Kingdom" target="_blank" onclick="trackMapDirectionsClick()">Get Directions</a>
          </div>`;

        const markerElement = new window.google.maps.marker.AdvancedMarkerElement({
          position: { lat: 53.75252211029793, lng: -1.4471098680939694 },
          map: map,
          title: 'Vintage in Oulton',
          content: document.createElement('div')
        });

        markerElement.content.innerHTML = contentString;

        const infowindow = new window.google.maps.InfoWindow({
          content: contentString
        });

        markerElement.addListener('click', function() {
          infowindow.open(map, markerElement);
        });
      }
    };

    // Track map directions click event
    window.trackMapDirectionsClick = function() {
      if (window.gtag) {
        window.gtag('event', 'click', {
          'event_category': 'Map Interaction',
          'event_label': 'Map Directions Click'
        });
      }
    };

    // Load the Google Maps script only if not already loaded
    const existingScript = document.querySelector(`script[src^="https://maps.googleapis.com/maps/api/js?key=AIzaSyBjw_vtvf2tIOY8hWhG0K94qVUvhSGvdvQ"]`);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBjw_vtvf2tIOY8hWhG0K94qVUvhSGvdvQ&callback=initMap&libraries=marker';
      script.async = true;
      script.defer = true;
      script.onerror = function() {
        console.error('Google Maps script failed to load.');
      };
      document.head.appendChild(script);
    }
  }, []);

  return (
    <Layout>
      <div className={styles.bodytext}>
        
        <div className={styles.textContainer}>
          <h2>How to get in touch</h2>
          <br />
          <p><b>Phone:</b> 0113 3934720</p>
          <p><b>Whatsapp:</b> 07795365104 / 07717523816</p>
          <p><b>Email:</b> <a href="mailto:vintageinoulton@gmail.com">vintageinoulton@gmail.com</a></p>
          <p><b>Instagram:</b> <a href="https://instagram.com/vintage.in.oulton" target="_blank" rel="noopener noreferrer">instagram.com/vintage.in.oulton</a></p>
          <p><b>Facebook:</b> <a href="https://facebook.com/vintageinoulton" target="_blank" rel="noopener noreferrer">facebook.com/vintageinoulton</a></p>
          <br />
          <hr />
          <br />
          <h2>Where to find us:</h2>
          <br />
          <p>81-83 Aberford Road, Oulton, Leeds, LS26 8SL</p>
          <br />
          <p>Find us next to Cooper’s Ford garage and a minute's walk from Lidl with 90 minutes free parking. Free street parking is available locally.</p>       
          <br />
        </div>
        <div id="map" style={{ height: '500px', width: '100%' }}></div>
      </div>
    </Layout>
  );
};

export default Contact;
