import React from 'react';
import Layout from '../components/Layout';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import styles from './Annie.module.css'; // Import the CSS module
import annie1 from '../assets/annie1.jpg';
import annie2 from '../assets/annie2.jpg';
import annie3 from '../assets/annie3.jpg';
import annie4 from '../assets/annie4.jpg';
import annie5 from '../assets/annie5.jpg';

const images = [
  annie1,
  annie2,
  annie3,
  annie4,
  annie5,
];

const Annie = () => {
  return (
    <Layout>
      <div className={styles.textContainer}>
        <h2>About Annie Sloan</h2>
        <p>Annie Sloan is an expert in decorative paint, colors, and techniques. She has been painting for decades and is recognized for her range of Chalk Paint®.</p>
        <p>Her products are known for their versatility, ease of use, and vibrant colors, making them popular among DIY enthusiasts and professionals alike.</p>
        <p>Visit our store to explore the wide range of Annie Sloan products available.</p>
      </div>
	  <div className={styles.carouselContainer}>
        <Carousel
          autoPlay
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          interval={5000}
        >
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index}`} />
            </div>
          ))}
        </Carousel>
      </div>
    </Layout>
  );
};

export default Annie;
