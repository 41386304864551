import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <ul className="social-icons">
                <li><a href="https://www.instagram.com/vintage.in.oulton/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
                <li><a href="https://www.facebook.com/vintageinoulton/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a></li>
                <li><a href="https://www.ebay.co.uk/usr/vintage-in-oulton" target="_blank" rel="noopener noreferrer"><i className="fab fa-ebay"></i></a></li>
                <li><a href="mailto:vintageinoulton@gmail.com"><i className="fas fa-envelope"></i></a></li>
                <li><a href="https://wa.me/+447795365104" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp"></i></a></li>
            </ul>
        </footer>
    );
};

export default Footer;
