import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Cabinets from './pages/Cabinets';
import Annie from './pages/Annie';
import './App.css';

const tagManagerArgs = {
  gtmId: 'GTM-NMLJZPRN', // Replace with your GTM container ID
};

const App = () => {
  useEffect(() => {
    // Initialize Google Tag Manager
    TagManager.initialize(tagManagerArgs);
  }, []);

  const location = useLocation();

  useEffect(() => {
    // Track page view on route change
    window.dataLayer.push({
      event: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cabinets" element={<Cabinets />} />
        <Route path="/annie" element={<Annie />} />
      </Routes>
    </div>
  );
};

const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

export default WrappedApp;
